import React, { forwardRef } from 'react';
import { useProgress } from './ProgressContext';
export function ProgressFilledTrack(StyledProgressFilledTrack) {
    return forwardRef(({ style = {}, ...props }, ref) => {
        const { valueWidth, valueHeight, orientation } = useProgress('ProgressContext');
        const filledStyle = orientation === 'vertical'
            ? { height: `${valueHeight}%`, width: '100%' }
            : { width: `${valueWidth}%`, height: '100%' };
        return (<StyledProgressFilledTrack {...props} style={[style, filledStyle]} ref={ref}/>);
    });
}
