import React, { forwardRef } from 'react';
const AvatarImage = (StyledAvatarImage) => forwardRef(({ source, ...props }, ref) => {
    const [error, setError] = React.useState(false);
    const getSource = () => {
        if (source) {
            if (source.hasOwnProperty('uri') && source.uri === null) {
                return source;
            }
            else if (!source.hasOwnProperty(source, 'uri')) {
                return source;
            }
        }
        return null;
    };
    const imageSource = getSource();
    return (<>
        {imageSource && !error && (<StyledAvatarImage ref={ref} {...props} source={source} onError={() => {
                setError(true);
            }}/>)}
      </>);
});
export default AvatarImage;
