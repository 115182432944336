import { useFocusRing, useFocus } from '@react-native-aria/focus';
import React, { forwardRef, createContext } from 'react';
import { composeEventHandlers } from '@gluestack-ui/utils';
import { usePress, useHover } from '@react-native-aria/interactions';
export const ActionsheetContext = createContext({});
export function ActionsheetItem(StyledActionsheetItem) {
    return forwardRef(({ children, isDisabled, isHovered: isHoveredProp, isPressed: isPressedProp, isFocused: isFocusedProp, isFocusVisible: isFocusVisibleProp, ...props }, ref) => {
        const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();
        const { pressProps, isPressed } = usePress({ isDisabled });
        const { isFocused, focusProps } = useFocus();
        const { isHovered, hoverProps } = useHover();
        return (<StyledActionsheetItem ref={ref} disabled={isDisabled} onPressIn={composeEventHandlers(props?.onPressIn, pressProps.onPressIn)} onPressOut={composeEventHandlers(props?.onPressOut, pressProps.onPressOut)} 
        // @ts-ignore - web only
        onHoverIn={composeEventHandlers(props?.onHoverIn, hoverProps.onHoverIn)} 
        // @ts-ignore - web only
        onHoverOut={composeEventHandlers(props?.onHoverOut, hoverProps.onHoverOut)} 
        // @ts-ignore - web only
        onFocus={composeEventHandlers(composeEventHandlers(props?.onFocus, focusProps.onFocus), focusRingProps.onFocus)} 
        // @ts-ignore - web only
        onBlur={composeEventHandlers(composeEventHandlers(props?.onBlur, focusProps.onBlur), focusRingProps.onBlur)} {...props} states={{
                hover: isHoveredProp || isHovered,
                focus: isFocusedProp || isFocused,
                active: isPressedProp || isPressed,
                disabled: isDisabled,
                focusVisible: isFocusVisibleProp || isFocusVisible,
                // @ts-ignore
                ...props.states,
            }} dataSet={{
                hover: isHoveredProp || isHovered ? 'true' : 'false',
                focus: isFocusedProp || isFocused ? 'true' : 'false',
                active: 
                // @ts-ignore
                isPressedProp || isPressed || props?.states?.active
                    ? 'true'
                    : 'false',
                disabled: isDisabled ? 'true' : 'false',
                focusVisible: isFocusVisibleProp || isFocusVisible ? 'true' : 'false',
                // @ts-ignore
                ...props.dataSet,
            }}>
          {children}
        </StyledActionsheetItem>);
    });
}
