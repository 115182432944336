import React, { forwardRef } from 'react';
import { useHover } from '@react-native-aria/interactions';
import { useToggleState } from '@react-stately/toggle';
import { useFormControlContext } from '@gluestack-ui/form-control';
import { mergeRefs } from '@gluestack-ui/utils';
export function Switch(StyledSwitch) {
    return forwardRef(({ disabled, isDisabled, isInvalid, defaultValue, onToggle, value, onValueChange, ...props }, ref) => {
        const formControlContext = useFormControlContext();
        const combinedProps = { ...formControlContext, ...props };
        const state = useToggleState({
            defaultSelected: !(defaultValue === null || defaultValue === undefined)
                ? defaultValue
                : !(value === null || value === undefined)
                    ? value
                    : false,
        });
        const checked = !(value === null || value === undefined)
            ? value
            : state.isSelected;
        const _ref = React.useRef(null);
        const { isHovered } = useHover({}, _ref);
        const mergedRef = mergeRefs([ref, _ref]);
        return (<StyledSwitch states={{
                hover: isHovered,
                disabled: disabled || isDisabled || combinedProps.isDisabled,
                invalid: isInvalid || combinedProps.isInvalid,
                checked: value || checked,
            }} dataSet={{
                hover: isHovered ? 'true' : 'false',
                disabled: disabled || isDisabled || combinedProps.isDisabled
                    ? 'true'
                    : 'false',
                invalid: isInvalid || combinedProps.isInvalid ? 'true' : 'false',
                checked: value || checked ? 'true' : 'false',
            }} disabled={disabled || isDisabled || combinedProps.isDisabled} onValueChange={(val) => {
                onValueChange && onValueChange(val);
                onToggle ? onToggle(val) : state.toggle();
            }} value={value || checked} {...combinedProps} ref={mergedRef}/>);
    });
}
