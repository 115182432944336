import React, { forwardRef, useContext } from 'react';
import { AccordionItemContext } from './Context';
import AnimatedHeight from './AnimatedHeight';
export const AccordionContent = (StyledAccordionContent) => forwardRef(({ children, ...props }, ref) => {
    const { regionProps, isExpanded } = useContext(AccordionItemContext);
    return (<AnimatedHeight hide={!isExpanded}>
        <StyledAccordionContent ref={ref} {...props} {...regionProps}>
          {children}
        </StyledAccordionContent>
      </AnimatedHeight>);
});
