import React, { forwardRef } from 'react';
import { useRadio } from './RadioProvider';
export const RadioIcon = (StyledRadioIcon) => forwardRef(({ children, forceMount = false, ...props }, ref) => {
    const { isChecked } = useRadio('RadioContext');
    if (forceMount || isChecked) {
        return (<StyledRadioIcon {...props} ref={ref}>
          {children}
        </StyledRadioIcon>);
    }
    return null;
});
