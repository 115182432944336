import React, { forwardRef } from 'react';
import { AccessibilityInfo } from 'react-native';
export function ToastTitle(StyledToastTitle) {
    return forwardRef(({ children, ...props }, ref) => {
        React.useEffect(() => {
            // Issue from react-native side
            // Hack for now, will fix this later
            AccessibilityInfo.announceForAccessibility(children);
        });
        return (<StyledToastTitle {...props} ref={ref} aria-live="assertive" aria-atomic="true" role="alert">
          {children}
        </StyledToastTitle>);
    });
}
