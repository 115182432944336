import React, { forwardRef } from 'react';
import { usePopoverContent } from './PopoverContext';
const PopoverHeader = (StyledPopoverHeader) => forwardRef(({ children, ...props }, ref) => {
    const { value } = usePopoverContent('PopoverContext');
    const { setHeaderMounted, headerId } = value;
    React.useEffect(() => {
        if (setHeaderMounted) {
            setHeaderMounted(true);
            return () => {
                setHeaderMounted(false);
            };
        }
        else {
            return () => { };
        }
    }, [setHeaderMounted]);
    return (<StyledPopoverHeader id={headerId} ref={ref} {...props}>
        {children}
      </StyledPopoverHeader>);
});
export default PopoverHeader;
