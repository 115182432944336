import React, { forwardRef, useContext, useEffect } from 'react';
import { AccordionItemContext } from './Context';
export const AccordionTitleText = (StyledAccordionTitleText) => forwardRef(({ children, ...props }, ref) => {
    const { setTitleText } = useContext(AccordionItemContext);
    useEffect(() => {
        if (typeof children === 'string') {
            setTitleText(children);
        }
    }, [children, setTitleText]);
    return (<StyledAccordionTitleText ref={ref} {...props}>
          {children}
        </StyledAccordionTitleText>);
});
