import { createContext } from 'react';
export const ToastContext = createContext({
    toastInfo: {},
    setToastInfo: () => { },
    setToast: () => '',
    removeToast: () => { },
    hideAll: () => { },
    isActive: () => false,
    visibleToasts: {},
    setVisibleToasts: () => { },
    hideToast: () => { },
    AnimationWrapper: { current: null },
    AnimatePresence: { current: null },
});
