import React, { forwardRef } from 'react';
import { useHover, usePress } from '@react-native-aria/interactions';
import { useFocusRing, useFocus } from '@react-native-aria/focus';
import { composeEventHandlers } from '@gluestack-ui/utils';
function Fab(StyledFab) {
    return forwardRef(({ children, isDisabled, isHovered: isHoveredProp, isPressed: isPressedProp, isFocused: isFocusedProp, isFocusVisible: isFocusVisibleProp, ...props }, ref) => {
        const { isFocusVisible, focusProps: focusRingProps } = useFocusRing();
        const { pressProps, isPressed } = usePress({ isDisabled });
        const { isFocused, focusProps } = useFocus();
        const { isHovered, hoverProps } = useHover();
        return (<StyledFab ref={ref} role={props?.role || 'button'} states={{
                hover: isHoveredProp || isHovered,
                focus: isFocusedProp || isFocused,
                active: isPressedProp || isPressed,
                disabled: isDisabled,
                focusVisible: isFocusVisibleProp || isFocusVisible,
            }} dataSet={{
                hover: isHoveredProp || isHovered ? 'true' : 'false',
                focus: isFocusedProp || isFocused ? 'true' : 'false',
                active: isPressedProp || isPressed ? 'true' : 'false',
                disabled: isDisabled ? 'true' : 'false',
                focusVisible: isFocusVisibleProp || isFocusVisible ? 'true' : 'false',
            }} disabled={isDisabled} {...props} onPressIn={composeEventHandlers(props?.onPressIn, pressProps.onPressIn)} onPressOut={composeEventHandlers(props?.onPressOut, pressProps.onPressOut)} onHoverIn={composeEventHandlers(props?.onHoverIn, hoverProps.onHoverIn)} onHoverOut={composeEventHandlers(props?.onHoverOut, hoverProps.onHoverOut)} onFocus={composeEventHandlers(composeEventHandlers(props?.onFocus, focusProps.onFocus), focusRingProps.onFocus)} onBlur={composeEventHandlers(composeEventHandlers(props?.onBlur, focusProps.onBlur), focusRingProps.onBlur)}>
          {children}
        </StyledFab>);
    });
}
export default Fab;
