import React from 'react';
export const ActionsheetContext = React.createContext({
    hideDragIndicator: false,
    handleClose: (() => { }),
    initialFocusRef: { current: null },
    finalFocusRef: { current: null },
    visible: false,
    backdropVisible: false,
    closeOnOverlayClick: false,
    handleCloseBackdrop: (() => { }),
    avoidKeyboard: false,
    bottomInset: 0,
    trapFocus: true,
    snapPoints: [],
    preventScroll: true,
});
