import React, { forwardRef } from 'react';
import { AlertDialogContext } from './Context';
import { OverlayAnimatePresence } from './OverlayAnimatePresence';
function AlertDialogBackdrop(StyledAlertDialogBackdrop, AnimatePresence) {
    return forwardRef(({ children, ...props }, ref) => {
        const { visible, closeOnOverlayClick, handleClose } = React.useContext(AlertDialogContext);
        return (<OverlayAnimatePresence visible={visible} AnimatePresence={AnimatePresence}>
          <StyledAlertDialogBackdrop ref={ref} onPress={() => {
                closeOnOverlayClick && handleClose();
            }} {...props}>
            {children}
          </StyledAlertDialogBackdrop>
        </OverlayAnimatePresence>);
    });
}
export default AlertDialogBackdrop;
