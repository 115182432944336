import React, { forwardRef } from 'react';
import { ActionsheetContext } from './context';
import { OverlayAnimatePresence } from './OverlayAnimatePresence';
function ActionsheetBackdrop(StyledActionsheetBackdrop, AnimatePresence) {
    return forwardRef(({ children, ...props }, ref) => {
        const { closeOnOverlayClick, handleClose, backdropVisible } = React.useContext(ActionsheetContext);
        return (<OverlayAnimatePresence visible={backdropVisible} AnimatePresence={AnimatePresence}>
          <StyledActionsheetBackdrop ref={ref} onPress={() => {
                closeOnOverlayClick && handleClose();
            }} 
        // ios
        accessibilityElementsHidden 
        // android
        importantForAccessibility="no-hide-descendants" aria-hidden={true} {...props}>
            {children}
          </StyledActionsheetBackdrop>
        </OverlayAnimatePresence>);
    });
}
export default ActionsheetBackdrop;
