import React from 'react';
export const AlertDialogContext = React.createContext({
    handleClose: (() => { }),
    initialFocusRef: { current: null },
    finalFocusRef: { current: null },
    visible: false,
    closeOnOverlayClick: false,
    avoidKeyboard: false,
    bottomInset: 0,
});
