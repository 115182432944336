/* eslint-disable react-native/no-inline-styles */
import React, { forwardRef } from 'react';
import { View } from 'react-native';
import { AlertDialogContext } from './Context';
import { Overlay } from '@gluestack-ui/overlay';
import { useControllableState, useKeyboardBottomInset, } from '@gluestack-ui/hooks';
export const AlertDialog = (StyledAlertDialog) => forwardRef(({ children, isOpen, onClose, defaultIsOpen = false, initialFocusRef, finalFocusRef, useRNModal, avoidKeyboard = false, closeOnOverlayClick = true, isKeyboardDismissable = true, animationPreset = 'fade', 
// @ts-ignore
_experimentalOverlay = false, ...props }, ref) => {
    const bottomInset = useKeyboardBottomInset();
    const [visible, setVisible] = useControllableState({
        value: isOpen,
        defaultValue: defaultIsOpen,
        onChange: (val) => {
            if (!val)
                onClose && onClose();
        },
    });
    const avoidKeyboardSpacer = (<View style={{
            // @ts-ignore
            pointerEvents: 'box-none',
            width: '100%',
            height: avoidKeyboard ? bottomInset : undefined,
        }}/>);
    const handleClose = React.useCallback(() => setVisible(false), [setVisible]);
    const contextValue = React.useMemo(() => {
        return {
            handleClose,
            initialFocusRef,
            finalFocusRef,
            closeOnOverlayClick,
            avoidKeyboard,
            bottomInset,
            visible,
        };
    }, [
        handleClose,
        initialFocusRef,
        closeOnOverlayClick,
        finalFocusRef,
        avoidKeyboard,
        bottomInset,
        visible,
    ]);
    if (_experimentalOverlay) {
        return (<AlertDialogContext.Provider value={contextValue}>
            <StyledAlertDialog {...props} ref={ref}>
              {children}
              {avoidKeyboard ? avoidKeyboardSpacer : null}
            </StyledAlertDialog>
          </AlertDialogContext.Provider>);
    }
    return (<Overlay isOpen={visible} onRequestClose={handleClose} isKeyboardDismissable={isKeyboardDismissable} animationPreset={animationPreset} useRNModal={useRNModal}>
          <AlertDialogContext.Provider value={contextValue}>
            <StyledAlertDialog {...props} ref={ref}>
              {children}
              {avoidKeyboard ? avoidKeyboardSpacer : null}
            </StyledAlertDialog>
          </AlertDialogContext.Provider>
        </Overlay>);
});
